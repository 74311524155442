import React from 'react';
// import Navbar from 'components/templates/Navbar';
// import Footer from '../templates/Footer';
import MainVisual from 'components/templates/MainVisual';

const HomePage: React.FC = () => (
  <>
    <div className="custom-body">
      {/* <Navbar /> */}
      <MainVisual />
      {/* <Footer /> */}
    </div>
  </>
);
export default HomePage;
